@import "../../../assets/var";

.el-label{
    height: auto;
    box-sizing: border-box;
    color: $grey700;
    display: inline-block;
    font-size: 16px;
    padding: 9px 15px;
    position: relative;

    .el-input__icon{
        width: 25px;
        //line-height: 40px;
        height: 100%;
        text-align: center;
        transition: all .3s;
    }

    .el-input__prefix{
       margin-left: 5px;
    }
    .el-input__suffix{
        margin-right: 5px;
    }

}
