.common-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 5px #999;
    min-width: 1200px;
    .wrapper {
        width: 1200px;
        margin: 0 auto;
        height: 60px;
    }

    .left-part {
        float: left;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        color: #333;
        cursor: pointer;
        text-decoration: none;
    }

    .right-part {
        width: auto;
        min-width: 1px;
        overflow-x: hidden;
        float: right;

        .avatar {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            margin: 15px;
            float: left;
            background-color: #fff;
        }

        .text-item {
            width: auto;
            min-width: 1px;
            overflow-x: hidden;
            float: left;
            height: 30px;
            line-height: 30px;
            margin: 15px 0;
            padding: 0 15px;
            font-size: 15px;
            color: #333;
            position: relative;
            cursor: pointer;
        }

        .icon-item {
            width: 30px;
            height: 30px;
            float: left;
            margin: 15px 0 15px 15px;
            color: #999;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            font-family: 'blog-icon';
            font-size: 21px;
        }
    }
}
