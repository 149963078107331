/* You can add global styles to this file, and also import other style files */
$lineHeight: 40px;
$pageWidth: 1200px;

$whiteBg: #fff;

// 组件主色调
$ccBlue: #11a1f8;
$ccGreen: #4dc60d;
$ccGrey: #cbcccf;
// 区域背景色
$bgGrey: #f5f7fa;
$bgBlue: #f8fcff;

$rtIconColor: #7f9db1;

// 色域
$blue50: #E3F2FD;
$blue100: #BBDEFB;
$blue200: #90CAF9;
$blue300: #64B5F6;
$blue400: #42A5F5;
$blue500: #2196F3;
$blue600: #1E88E5;
$blue700: #1976D2;
$blue800: #1565C0;
$blue900: #0D47A1;

$grey50: #FAFAFA;
$grey100: #F5F5F5;
$grey200: #EEEEEE;
$grey300: #E0E0E0;
$grey400: #BDBDBD;
$grey500: #9E9E9E;
$grey600: #757575;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121;

$red50: #FFEBEE;
$red100: #FFCDD2;
$red200: #EF9A9A;
$red300: #E57373;
$red400: #EF5350;
$red500: #F44336;
$red600: #E53935;
$red700: #D32F2F;
$red800: #C62828;
$red900: #B71C1C;

$green50: #E8F5E9;
$green100: #C8E6C9;
$green200: #A5D6A7;
$green300: #81C784;
$green400: #66BB6A;
$green500: #4CAF50;
$green600: #43A047;
$green700: #388E3C;
$green800: #2E7D32;
$green900: #1B5E20;

$borderColor: #dde1e3;

$border-weak: 1px $grey300 solid;
$blue-hover: lighten($blue600, 5%);
$blue-active: darken($blue600, 10%);


@keyframes rotating {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

