@import "../../assets/var";

.page-container {
    padding: 20px 40px;

    .table-wrapper.with-table-bordered .ant-table-body {
        box-sizing: border-box;
        border: $border-weak;
        border-bottom: none;
    }

    .add-btn, .search-ipt {
        position: absolute;
        top: 0;
        right: 0;
    }

    .add-btn {
        right: 260px;
    }

    .key {
        float: left;
        width: 150px;
        color: #333;
        font-weight: bold;
    }

    .value {
        float: left;
        max-width: 575px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }

    .modify-btn {
        display: none;
        float: left;
        margin-top: 9px;
        margin-left: 5px;
    }
}

.component-auth {
    .row {
        padding: 15px 0;
        border-bottom: 1px solid #e0e0e0;
        font-family: blog-icon;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:hover {
            .modify-btn {
                display: inline-block;
            }
        }

        .modify-btn {
            margin-top: 14px;
        }
    }
}

.component-personal {
    padding: 20px 200px 0 40px;
    position: relative;
    height: auto;
    .row {
        padding: 30px 0;
        border-bottom: 1px solid #e0e0e0;
        font-family: blog-icon;
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            .modify-btn {
                display: inline-block;
            }
        }

    }
    .cat-info {
        margin: 16px 0;
    }
    .ant-upload.ant-upload-select-picture-card {
        border-radius: 100%;
        margin: 0;
        > .ant-upload {
            padding: 0px;
        }
    }
    .avatar-box {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        cursor: pointer;

        &:hover {
            .avatar-mask {
                opacity: 1;
            }
        }
    }

    .avatar-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11;
        opacity: 0;
        border-radius: 100%;
        transition: opacity .2s ease-in;
        background-color: rgba(0, 0, 0, .5);

        .btn {
            margin-top: 40px;
            span {
                display: block;
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 40px;
                height: 40px;

                &.icon-font {
                    font-size: 32px;
                }
            }
        }
    }

    .add-mgt {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .app-list {
        .modify-btn {
            margin-top: 14px;
        }
    }
}

.login-frame {
    background: #fff;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(26, 26, 26, .1);
    box-sizing: border-box;
    width: 432px;
    margin: 125px auto 0 auto;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 10px;
    .frame-header {
        color: #0084ff;
        .title {
            font-size: 38px;
            font-weight: bold;
        }

        .slogen {
            font-size: 22px;
            margin-top: 10px;
        }
    }
    .app-tips {
        color: #0084ff;
        font-size: 16px;
    }
    .frame-body {
        padding: 0 40px 36px;
        .row {
            line-height: 40px;
            margin-top: 20px;
            text-align: left;

            &.error {
                box-sizing: border-box;
                margin-top: 0;
                height: 40px;
                color: $red400;
            }
        }

        .ant-input-affix-wrapper .ant-input-prefix {
            font-family: blog-icon;
            left: 8px;
        }

        .ant-input {
            font-size: 16px;
            width: 352px;
            //height: 40px;
            //line-height: 40px;
            outline: none !important;
            border: none;
            border-bottom: $border-weak;
            border-radius: 0;
            box-shadow: none;
            .el-input__prefix {
                left: 3px;
            }
        }

        .el-checkbox {
            margin-left: 8px;
        }

        .el-button {
            width: 352px;
        }
    }

}

.auth-pagination {
    margin: 16px 0;
    float: right;
}

.row {
    display: block;
    clear: both;
    width: 100%;
    position: relative;
    height: auto;
    overflow: hidden;
    min-height: 1px;
}

.cat-info {
    margin: 16px 0 30px 0;
    position: relative;
}

.cat-name {
    font-size: 24px;
    line-height: 30px;
}

