.container{
    .left-part{
        width: 200px;
        position: relative;
        float: left;
        min-height: 600px;

        &::after{

        }
    }

    .right-part{
        width: 1000px;
        box-sizing: border-box;
        height: auto;
        min-height: 600px;
        overflow: hidden;
        float: right;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0px;
            width: 1px;
            background-color: #dde1e3;
        }
    }

}