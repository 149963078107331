html{
  background-color: #f8f8f8;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent !important;
}

@font-face {
  font-family: 'blog-icon';  /* project id 760845 */
  src: url('//at.alicdn.com/t/font_760845_e256a9tvre.eot');
  src: url('//at.alicdn.com/t/font_760845_e256a9tvre.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_760845_e256a9tvre.woff') format('woff'),
  url('//at.alicdn.com/t/font_760845_e256a9tvre.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_760845_e256a9tvre.svg#blog-icon') format('svg');
}

.icon-font{
  font-family: blog-icon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
}

.container{
  width: 1202px;
  height: auto;
  min-height: 600px;
  background-color: #fff;
  border: 1px solid #dde1e3;
  margin: 100px auto 30px;
  border-radius: 0 5px 5px;
  position: relative;
  overflow: hidden;
}
